// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  //url: 'http://localhost:8100',
  url: 'https://saferoads.ph',
  production: false,
  //cmsServiceURL:'https://saferoadsph-reports-service.azurewebsites.net/api/v1',
  //userServiceURL:'https://saferoadsph-user-service.azurewebsites.net/api/v1',
  //courseServiceURL:'https://saferoadsph-modules-service.azurewebsites.net/api/v1',
  //paymentServiceURL:'https://saferoadsph-payments-service.azurewebsites.net/api/v1',
  luxandURL: 'https://api.luxand.cloud',
  luxandToken: '6265519eb3ee4daf850419432ed35954', // saferoad account

  //url: 'https://saferoads.ph',
  cmsServiceURL: 'https://saferoadsph-reports-service-legacy.azurewebsites.net/api/v1',
  userServiceURL: 'https://saferoadsph-user-service-legacy.azurewebsites.net/api/v1',
  //userServiceURL: 'http://localhost:9090/api/v1',
  courseServiceURL:
    'https://saferoadsph-module-service-legacy.azurewebsites.net/api/v1',
    //'http://localhost:9080/api/v1',
  paymentServiceURL:
    'https://saferoadsph-payment-service-legacy.azurewebsites.net/api/v1',
  dsControlPanelURL:'https://ad.saferoads.ph/',
  //dsControlPanelURL:'https://ad-saferoadsph-demo.azurewebsites.net/',

  azureFaceApiKey1: '02bd84df4b394ec38e6f5151fa677840',
  azureFaceApiEndPoint: 'https://saferoadsph.cognitiveservices.azure.com/',

  paymongoURL: 'https://api.paymongo.com/v1/',
  publicKey: 'pk_test_fcaNPNxNA7SLhByaeWzgzGhD', //paymongo public key
  secretKey: 'sk_test_G7wM4okPefCZ1mrFakvoPC8d', //paymongo secret key
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
