import { Component } from '@angular/core';
import { StorageService } from './services/storage/storage.service';
import { filter } from 'rxjs/operators';
import { Router,NavigationEnd } from '@angular/router';
declare let gtag: Function;
declare let fbq:Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  //router:any;
  constructor(private router: Router,
    private storageService: StorageService) {
    this.storageService.init();
    /* router.events.subscribe((y: NavigationEnd) => {
      if(y instanceof NavigationEnd){
        fbq('track', 'PageView');
      }
    }); */
  }

  ngOnInit() {
    this.setUpAnalytics;
  }

  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
            gtag('config', 'G-7ZDML9GTL5',
                {
                    page_path: event.urlAfterRedirects
                }
            );
        });
    }
}
