import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User, Login, ChangePassword, School, UserLastLogout, FaceLogs } from 'src/app/interfaces/user';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  getCountries() {
    return this.httpClient.get<any>('assets/data/countries.json')
        .toPromise()
}

  loginHeader(login: Login) {
    var encrpytion = btoa(login.email + ':' + login.password);
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${encrpytion}`,
      }),
    };
  }

  login(login: Login) {
    return this.httpClient
      .post(environment.userServiceURL + '/login', {}, this.loginHeader(login))
      .toPromise();
  }

  getProfile() {
    return this.httpClient
      .get(environment.userServiceURL + '/safe-road-users/profile')
      .toPromise();
  }

  getProfileWithOutToDoList(param) {
    return this.httpClient
      .get(environment.userServiceURL + '/safe-road-users/profile?withOutToDoList='+param)
      .toPromise();
  }

  editProfile(user: User) {
    return this.httpClient
      .put(environment.userServiceURL + '/safe-road-users/edit-profile', user)
      .toPromise();
  }

  register(user: User) {
    return this.httpClient
      .post(environment.userServiceURL + '/safe-road-users/register', user)
      .toPromise();
  }

  registerWithPhoto(user: User, studentUploadPhoto) {
    const formData = new FormData();
    formData.append(
      'file',
      studentUploadPhoto.blob,
      studentUploadPhoto.blob.name
    );
    formData.append('object', JSON.stringify(user));

    return this.httpClient
      .post(
        environment.userServiceURL + '/safe-road-users/register-with-photo',
        formData
      )
      .toPromise();
  }

  addStudentUserWithPhoto(user: User, studentUploadPhoto) {
    const formData = new FormData();
    formData.append(
      'file',
      studentUploadPhoto.blob,
      studentUploadPhoto.blob.name
    );
    formData.append('object', JSON.stringify(user));

    return this.httpClient
      .post(
        environment.userServiceURL +
          '/driving-school/add-student-user-with-photo',
        formData
      )
      .toPromise();
  }

  verifyAccount(code) {
    return this.httpClient
      .get(
        environment.userServiceURL +
          '/safe-road-users/email-confirmation?code=' +
          code
      )
      .toPromise();
  }

  resendCode(email) {
    return this.httpClient
      .get(
        environment.userServiceURL +
          '/sysAdmin/email/resend-confirmation?email=' +
          email
      )
      .toPromise();
  }

  getFaceSettings() {
    return this.httpClient
      .get(
        environment.userServiceURL +
          '/safe-road-users/face-settings?id=1'
      )
      .toPromise();
  }

  
  getFaceLoginSettings() {
    return this.httpClient
      .get(
        environment.userServiceURL +
          '/safe-road-users/getFaceLoginSettings'
      )
      .toPromise();
  }

  changePassword(password: ChangePassword) {
    return this.httpClient
      .post(
        environment.userServiceURL + '/sysAdmin/email/change-password',
        password
      )
      .toPromise();
  }

  updateDrivingSchoolReferralCode(referralCode) {
    return this.httpClient
      .put(
        environment.userServiceURL +
          '/safe-road-users/update-ds-profile-id/' +
          referralCode,
        {}
      )
      .toPromise();
  }

  getUserLastLogout(email:any) {
    return this.httpClient
      .get(
        environment.userServiceURL +
          '/safe-road-users/get-user-last-logout?email='+email,
        {}
      )
      .toPromise();
  }

  getUserLastLogoutNoAuth(email:any) {
    return this.httpClient
      .get(
        environment.userServiceURL +
          '/safe-road-users/get-user-last-logout-no-auth?email='+email,
        {}
      )
      .toPromise();
  }

  updateUserLastLogout(user:UserLastLogout) {
    return this.httpClient
      .put(
        environment.userServiceURL +
          '/safe-road-users/update-user-last-logout',user,
        {}
      )
      .toPromise();
  }

  updateUserFaceFailedCount(user:UserLastLogout) {
    return this.httpClient
      .put(
        environment.userServiceURL +
          '/safe-road-users/update-user-face-failed-count',user,
        {}
      )
      .toPromise();
  }

  //Upload
  uploadProfilePhoto(file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(
      environment.userServiceURL + '/upload-photo',
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  getDsProfileById(id) {
    return this.httpClient
      .get(environment.userServiceURL + '/driving-school/view-profile/?' + id)
      .toPromise();
  }

  getDsProfileByDsCode(dsCode) {
    return this.httpClient
      .get(environment.userServiceURL + '/driving-school/view-profile-dsCode?dsCode=' + dsCode)
      .toPromise();
  }

  getDsListByBranchCode(branchCode){
    return this.httpClient
    .get(environment.userServiceURL + '/driving-school/driving-school-list-branch-code?branchCode=' + branchCode)
    .toPromise();
  }

  //Driving School

  getAllDrivingSchool() {
    return this.httpClient
      .get(environment.userServiceURL + '/driving-school/driving-school-list')
      .toPromise();
  }

  getAllValidatedDrivingSchool() {
    return this.httpClient
      .get(environment.userServiceURL + '/driving-school/driving-school-list-validated')
      .toPromise();
  }

  getShoolProfile() {
    return this.httpClient
      .get(environment.userServiceURL + '/driving-school/view-profile')
      .toPromise();
  }

  editSchoolProfile(schoolProfile: School) {
    return this.httpClient
      .put(
        environment.userServiceURL + '/driving-school/edit-profile',
        schoolProfile
      )
      .toPromise();
  }

  getStudentList() {
    return this.httpClient
      .get(environment.userServiceURL + '/driving-school/student-list')
      .toPromise();
  }

  getUserList() {
    return this.httpClient
      .get(environment.userServiceURL + '/driving-school/user-list')
      .toPromise();
  }

  getStudentProfile(studentId) {
    return this.httpClient
      .get(
        environment.userServiceURL +
          '/driving-school/view-student-user/' +
          studentId
      )
      .toPromise();
  }

  getStudentProfileV2(studentId) {
    return this.httpClient
      .get(
        environment.userServiceURL +
          '/driving-school/view-student-profile/' +
          studentId
      )
      .toPromise();
  }

  editStudentProfile(studentId, user: User) {
    return this.httpClient
      .put(
        environment.userServiceURL +
          '/driving-school/edit-student-user/' +
          studentId,
        user
      )
      .toPromise();
  }

  addStudent(user: User) {
    return this.httpClient
      .post(
        environment.userServiceURL + '/driving-school/add-student-user',
        user
      )
      .toPromise();
  }

  deleteStudentProfile(studentId) {
    return this.httpClient
      .delete(
        environment.userServiceURL +
          '/driving-school/delete-student-user/' +
          studentId
      )
      .toPromise();
  }

  updateUserLuxand(luxandId) {
    var obj = {
      luxandId: luxandId,
    };
    return this.httpClient
      .put(
        environment.userServiceURL + '/safe-road-users/update-luxand-id',
        luxandId
      )
      .toPromise();
  }

  // Luxand
  loadHeader() {
    return {
      headers: new HttpHeaders({
        token: environment.luxandToken,
        Accept: 'multipart/form-data',
      }),
    };
  }

  // Luxand
  loadHeader2() {
    return {
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    };
  }

  registerLuxand(obj, email) {
     var file = new File([obj.blob], obj.filepath, {
      lastModified: Date.now(),
    }); 

    var formData = new FormData();
    formData.append('name', email);
    formData.append('photos', file, 'file');
    formData.append('store', '1');
    return this.httpClient
      .post<any>(
        environment.luxandURL + '/v2/person',
        formData,
        this.loadHeader()
      )
      .toPromise();
  }

  registerLuxandv2(obj, email) {

   var formData = new FormData();
   formData.append('name', email);
   formData.append('photos', obj);
   formData.append('store', '1');
   return this.httpClient
     .post<any>(
       environment.luxandURL + '/v2/person',
       formData,
       this.loadHeader()
     )
     .toPromise();
 }

  forgotPassword(obj) {
    return this.httpClient
      .post(environment.userServiceURL + '/safe-road-users/forgotPassword', obj)
      .toPromise();
  }

  updateLuxand(obj, luxandId) {
    var file = new File([obj.blob], obj.filepath, {
      lastModified: Date.now(),
    });

    var formData = new FormData();
    formData.append('photo', file, 'file');
    formData.append('store', '1');
    return this.httpClient
      .post<any>(
        environment.luxandURL + '/subject/' + luxandId,
        formData,
        this.loadHeader()
      )
      .toPromise();
  }

  deleteLuxand(luxandId) {
    return this.httpClient
      .delete<any>(
        environment.luxandURL + '/subject/' + luxandId,
        this.loadHeader()
      )
      .toPromise();
  }

  deleteLuxandPerson(uuid) {
    return this.httpClient
      .delete<any>(
        environment.luxandURL + '/person?uuid='+ uuid,
        this.loadHeader()
      )
      .toPromise();
  }

  getLuxandIdByEmail(email) {
    return this.httpClient
      .get<any>(
        environment.userServiceURL +
          '/safe-road-users/get-luxand-id?email=' +
          email,
        this.loadHeader2()
      )
      .toPromise();
  }

  getFaceKeyByEmail(email) {
    return this.httpClient
      .get<any>(
        environment.userServiceURL +
          '/safe-road-users/get-face-key?email=' +
          email,
        this.loadHeader2()
      )
      .toPromise();
  }

  verificationLuxand(obj, luxandId) {
    var file = new File([obj.blob], obj.filepath, {
      lastModified: Date.now(),
    });

    var formData = new FormData();
    formData.append('photo', file, 'file');
    return this.httpClient
      .post<any>(
        environment.luxandURL + '/photo/verify/' + luxandId,
        formData,
        this.loadHeader()
      )
      .toPromise();
  }

  verificationLuxandV2(obj) {

    var formData = new FormData();
    formData.append('photo', obj);
    return this.httpClient
      .post<any>(
        environment.luxandURL + '/photo/search/v2',
        formData,
        this.loadHeader()
      )
      .toPromise();
  }

   // Luxand
   loadHeaderLiveness() {
    return {
      headers: new HttpHeaders({
        token: environment.luxandToken
        // 'Content-Type': 'multipart/form-data;',
        // Accept: 'multipart/form-data',
      }),
    };
  }

checkImageLiveness(obj) {

  var formData = new FormData();
  formData.append('photo', obj);
  return this.httpClient
    .post<any>(
      environment.luxandURL + '/photo/liveness/v2',
      formData,
      this.loadHeaderLiveness()
    )
    .toPromise();
}

  faceLogin(faceKey, luxandId) {
    var obj = {
      faceKey: faceKey,
      luxandId: luxandId,
    };
    return this.httpClient
      .post<any>(environment.userServiceURL + '/login/face-login', obj)
      .toPromise();
  }

  faceLoginV2(faceKey, email) {
    var obj = {
      faceKey: faceKey,
      email: email,
    };
    return this.httpClient
      .post<any>(environment.userServiceURL + '/login/face-login-v2', obj)
      .toPromise();
  }

  uploadFaceLogV2(faceLogs: FaceLogs, studentUploadPhoto) {
    const formData = new FormData();
    formData.append(
      'file',
      studentUploadPhoto,
      studentUploadPhoto.name
    );
    formData.append('object', JSON.stringify(faceLogs));

    return this.httpClient
      .post(
        environment.userServiceURL + '/safe-road-users/create-face-recognition-image',
        formData
      )
      .toPromise();
  }

  uploadFaceLog(faceLogs: FaceLogs, studentUploadPhoto) {
    const formData = new FormData();
    formData.append(
      'file',
      studentUploadPhoto.blob,
      studentUploadPhoto.blob.name
    );
    formData.append('object', JSON.stringify(faceLogs));

    return this.httpClient
      .post(
        environment.userServiceURL + '/safe-road-users/create-face-recognition-image',
        formData
      )
      .toPromise();
  }

  getFaceLogs(studentId) {
    return this.httpClient
      .get<any>(
        environment.userServiceURL +
          '/safe-road-users/get-student-face-recognition-images?studentId=' +
          studentId,
      )
      .toPromise();
  }

  getFaceTopics() {
    return this.httpClient
      .get<any>(
        environment.userServiceURL +
          '/safe-road-users/get-face-topics'
          ,
      )
      .toPromise();
  }

  getStudentFaceCapturedLogs(studentId) {
    return this.httpClient
        .get(
            environment.userServiceURL +
                "/safe-road-users/get-student-face-recognition-images?studentId=" +
                studentId
        )
        .toPromise();
}
}
