import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UserService } from 'src/app/services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private userService: UserService,
    private router: Router,
    private storageService: StorageService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const role = route.data.role.toUpperCase();
    return this.userService
      .getProfileWithOutToDoList('Y')
      .then((res: any) => {
        //console.log(res.result[0].todoList);
        if (res.statusCode == 409) {
          this.router.navigate(['/verification']);
          return false;
        }
        if (res.statusCode == 200) {
          this.storageService.set('profile_photo', res.result[0].photo);
          this.storageService.set('luxandId', res.result[0].luxandId);
          //this.storageService.set('todoList', res.result[0].todoList);
          this.storageService.set(
            'name',
            res.result[0].firstName + ' ' + res.result[0].lastName
          );
          if (res.result[0].userType.toUpperCase() != role) {
            if (res.result[0].userType.toUpperCase() == 'STUDENT') {
              this.router.navigate(['/student']);
            } else {
              this.router.navigate(['/school']);
            }
          }

          return true;
        }
      })
      .catch((err) => {
        this.storageService.clear();
        //location.reload();
        this.router.navigate(['']);
        return false;
      });
  }
}
