import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { UserService } from 'src/app/services/user/user.service';
import { Subject, Observable, throwError, EMPTY, from } from 'rxjs';
import { catchError, switchMap, tap, map, finalize } from 'rxjs/operators';

import { StorageService } from 'src/app/services/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard  {
  private constructor(
    private userService: UserService,
    private router: Router,
    private storageService: StorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.storageService.get('token').then((res) => {
      if (res) {
        this.router.navigate(['/student']);
        return false;
      } else {
        return true;
      }
    });
  }
}
