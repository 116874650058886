import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { User } from 'src/app/interfaces/user';
import { UserService } from 'src/app/services/user/user.service';
import * as moment from 'moment';
import { CmsService } from 'src/app/services/cms/cms.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.page.html',
  styleUrls: ['./forgot.page.scss'],
})
export class ForgotPage implements OnInit {
  email;
  birthdate;

  constructor(
    public modalController: ModalController,
    private userService: UserService,
    private cmsService: CmsService,
    private loadingController: LoadingController
  ) {}

  ngOnInit() {}

  convertDate(date) {
    return moment(new Date(date)).format('YYYY-MM-DD');
  }

  loading;
  async presentLoading(msg) {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: msg,
    });
    await this.loading.present();
  }

  submit() {
    this.presentLoading('Submitting...');
    var obj = {
      birthdate: this.convertDate(this.birthdate),
      email: this.email,
    };

    this.userService
      .forgotPassword(obj)
      .then((res: any) => {
        if (res.statusCode == 200) {
          this.cmsService.successMessageToast(
            'Password has been sent to your email : ' + obj.email
          );
        } else {
          this.cmsService.errorMessageToast('No Record Found in the Database');
        }
        this.loading.dismiss();

        this.birthdate = null;
        this.email = null;
      })
      .catch((err) => {
        this.loading.dismiss();
        this.cmsService.errorMessageToast('Please Contact IT Administrator');
      });
  }

  close() {
    this.modalController.dismiss();
  }
}
