import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { LoadingController } from '@ionic/angular';

import { Subject, Observable, throwError, EMPTY, from } from 'rxjs';
import { catchError, switchMap, tap, map, finalize } from 'rxjs/operators';
import { StorageService } from '../services/storage/storage.service';
import { ToastController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  isLoading = false;
  loading: any;
  constructor(
    private loadingController: LoadingController,
    private storageService: StorageService,
    public toastController: ToastController
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return from(this.storageService.get('token')).pipe(
      switchMap((token) => {
        // if Not Login
        if ((request.url.endsWith('login') == false) && !request.url.includes('checkout_sessions')) {
          if (token) {
            request = request.clone({
              setHeaders: {
                Authorization: `${token}`,
              },
            });
          }
        }

        // this.showLoader();
        return next.handle(request).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              if (request.url.endsWith('login') == true) {
                if (event.headers.get('Authorization')) {
                  this.storageService.set(
                    'token',
                    event.headers.get('Authorization')
                  );
                }
              }
            }
            //  this.dismiss();
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status == 401) {
              this.errorMessageToast(error.error.error);
            }

            //this.dismiss();
            return throwError(error);
          })
        );
      })
    );
  }

  async showLoader() {
    return await this.loadingController
      .create({
        spinner: 'circular',
      })
      .then((a) => {
        a.present().then(() => {
          //console.log('presented');
          if (!this.isLoading) {
            a.dismiss().then(() => {
              // console.log('abort presenting');
            });
          }
        });
      });
  }
  async dismiss() {
    let topLoader = await this.loadingController.getTop();
    while (topLoader) {
      if (!(await topLoader.dismiss())) {
        break;
      }
      topLoader = await this.loadingController.getTop();
    }
  }

  async errorMessageToast(message) {
    const toast = await this.toastController.create({
      color: 'danger',
      message: message,
      duration: 5000,
    });
    toast.present();
  }
}
