import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  constructor(private httpClient: HttpClient,
    private toastController: ToastController,
    private loadingController: LoadingController) {}

  getContent(page) {
    return this.httpClient
      .get(environment.cmsServiceURL + '/cms/getCMSDataByPage/' + page)
      .toPromise();
  }

  getFAQs(page) {
    return this.httpClient
      .get(environment.cmsServiceURL + '/faq/getFAQDataByPage/' + page)
      .toPromise();
  }

  getRegions() {
    return this.httpClient
      .get(environment.cmsServiceURL + '/address/allRegion')
      .toPromise();
  }

  getProvinces(regionId) {
    return this.httpClient
      .get(environment.cmsServiceURL + '/address/getStateByRegion/' + regionId)
      .toPromise();
  }

  getProvincesByRegionName(regionName) {
    return this.httpClient
      .get(environment.cmsServiceURL + '/address/getStateByRegionName/' + regionName)
      .toPromise();
  }

  getCities(provinceId) {
    return this.httpClient
      .get(environment.cmsServiceURL + '/address/getCityByState/' + provinceId)
      .toPromise();
  }

  getCitiesByProvinceName(provinceName) {
    return this.httpClient
      .get(environment.cmsServiceURL + '/address/getCityByStateName/' + provinceName)
      .toPromise();
  }


  getModules(){
    return this.httpClient
    .get(environment.cmsServiceURL + '/module-course')
    .toPromise();
  }
  async errorMessageToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 5000,
      color: 'danger',
    });
    toast.present();
  }

  async successMessageToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 5000,
      color: 'success',
    });
    toast.present();
  }

  loading
  async presentLoading(msg) {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: msg,
    });
    await this.loading.present();
  }

  dismissLoading() {
    this.loading.dismiss();
  }
}
