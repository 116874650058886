import { Component, OnInit, Input  } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-module-popover',
  templateUrl: './module-popover.page.html',
  styleUrls: ['./module-popover.page.scss'],
})
export class ModulePopoverPage implements OnInit {
  @Input() results: any;
  @Input () languages: any;
  @Input () currentLanguageCode: any;
  @Input() topicId;
  modules:any;
  accordionActive = null;
  constructor(public modalController: ModalController) {}

  ngOnInit() {
      this.modules = this.results.studentCourse.studentModules;
      if(this.results.currentTopicDetails){
        this.selectAccordion(this.results.currentTopicDetails.moduleId);
      }
      
  }

  selectAccordion(a) {
    if (this.accordionActive == a) {
      this.accordionActive = null;
    } else {
      this.accordionActive = a;
    }
  }

  close() {
    this.modalController.dismiss({
      'topicId': this.topicId,
      'currentLanguageCode': this.currentLanguageCode    
    
    });
  }

  loadDetails(topicId){
    this.modalController.dismiss({
      'topicId': topicId,
      'currentLanguageCode': this.currentLanguageCode,
      'quiz' : false 
    });
  }

  showGuideLine(topicId){
    this.modalController.dismiss({
      'topicId': topicId,
      'currentLanguageCode': this.currentLanguageCode,
      'quiz' : true    
    });
   
  }

}
