import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

import { AppRoutingModule } from './app-routing.module';
import { Drivers, Storage } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

// Components
import { AppComponent } from './app.component';
import { ForgotPage } from './pages/home/forgot/forgot.page';
import { ModulePopoverPage } from './pages/student/module-popover/module-popover.page';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './providers/interceptor.service';
import { FormsModule } from '@angular/forms';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [AppComponent, ForgotPage, ModulePopoverPage],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        IonicStorageModule.forRoot({
            driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB],
        }),
        HttpClientModule,
        CommonModule,
        FormsModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
        FileOpener,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
