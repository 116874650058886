import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null;
  constructor(private storage: Storage) {}

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    await this.storage.defineDriver(CordovaSQLiteDriver);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  public set(key: string, value: any) {
    this._storage?.set(key, value);
  }

  async get(key) {
    return await this.storage.get(key);
  }

  async clear() {
    await this.storage.clear();
  }

  async remove(key) {
    this.storage.remove(key);
  }

  async clearAll() {
    var x = await this.storage.keys();
    //console.log(x);
  }

  observableStoragetGet(key) {
    return from(
      this.storage.get(key).then((res) => {
        return res;
      })
    );
  }
}
