import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';
import { LoginGuard } from './guards/auth/login.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home/home.module').then((m) => m.HomePageModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/home/login/login.module').then((m) => m.LoginPageModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'create',
    loadChildren: () =>
      import('./pages/home/create/create.module').then(
        (m) => m.CreatePageModule
      ),
    canActivate: [LoginGuard],
  },

  {
    path: 'create/:userType',
    loadChildren: () =>
      import('./pages/home/create/create.module').then(
        (m) => m.CreatePageModule
      ),
    canActivate: [LoginGuard],
  },

  {
    path: 'student',
    loadChildren: () =>
      import('./pages/student/student/student.module').then(
        (m) => m.StudentPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      role: 'STUDENT',
    },
  },

  {
    path: 'school',
    loadChildren: () =>
      import('./pages/school/school/school.module').then(
        (m) => m.SchoolPageModule
      ),
    canActivate: [AuthGuard],
    data: {
      role: 'SCHOOL',
    },
  },

  {
    path: 'verification',
    loadChildren: () =>
      import('./pages/home/verification/verification.module').then(
        (m) => m.VerificationPageModule
      ),
  },
  {
    path: 'delete-modal',
    loadChildren: () =>
      import('./pages/school/delete-modal/delete-modal.module').then(
        (m) => m.DeleteModalPageModule
      ),
  },
  {
    path: 'video',
    loadChildren: () =>
      import('./pages/home/video/video.module').then((m) => m.VideoPageModule),
  },
  {
    path: 'progress-detail-modal',
    loadChildren: () =>
      import(
        './pages/student/modals/progress-detail-modal/progress-detail-modal.module'
      ).then((m) => m.ProgressDetailModalPageModule),
  },
  {
    path: 'data-protection-agreement-modal',
    loadChildren: () =>
      import(
        './pages/home/modals/data-protection-agreement-modal/data-protection-agreement-modal.module'
      ).then((m) => m.DataProtectionAgreementModalPageModule),
  },
  {
    path: 'face-capture-modal',
    loadChildren: () =>
      import(
        './pages/home/modals/face-capture-modal/face-capture-modal.module'
      ).then((m) => m.FaceCaptureModalPageModule),
  },
  {
    path: 'page/:blockId',
    loadChildren: () =>
      import('./pages/home/page/page.module').then((m) => m.PagePageModule),
  },
  {
    path: 'create-account-confirmation-modal',
    loadChildren: () =>
      import(
        './pages/home/modals/create-account-confirmation-modal/create-account-confirmation-modal.module'
      ).then((m) => m.CreateAccountConfirmationModalPageModule),
  },
  {
    path: 'payment-confirmation-modal',
    loadChildren: () =>
      import(
        './pages/student/modals/payment-confirmation-modal/payment-confirmation-modal.module'
      ).then((m) => m.PaymentConfirmationModalPageModule),
  },
  {
    path: 'selected-driving-school-modal',
    loadChildren: () =>
      import(
        './pages/student/modals/selected-driving-school-modal/selected-driving-school-modal.module'
      ).then((m) => m.SelectedDrivingSchoolModalPageModule),
  },
  {
    path: 'payment-confirmation-modal',
    loadChildren: () =>
      import(
        './pages/school/modals/payment-confirmation-modal/payment-confirmation-modal.module'
      ).then((m) => m.PaymentConfirmationModalPageModule),
  },
  {
    path: 'qr/:id',
    loadChildren: () =>
      import('./pages/home/qr/qr.module').then((m) => m.QrPageModule),
  },
  {
    path: 'create-account-with-dscode/:dsCode',
    loadChildren: () =>
      import('./pages/home/create-account-with-dscode/create-account-with-dscode.module').then((m) => m.CreateAccountWithDscodePageModule),
  },
  {
    path: 'face-verification',
    loadChildren: () => import('./pages/student/face-verification/face-verification.module').then( m => m.FaceVerificationPageModule)
  },
  {
    path: 'create-account-with-dscode',
    loadChildren: () => import('./pages/home/create-account-with-dscode/create-account-with-dscode.module').then( m => m.CreateAccountWithDscodePageModule)
  },
  {
    path: 'certificate-student/:courseId/:studentId',
    loadChildren: () =>
      import('./pages/home/certificate-student/certificate-student.module').then((m) => m.CertificateStudentPageModule),
  },
  {
    path: 'receipt-student/:studentId/:paymentId',
    loadChildren: () =>
      import('./pages/home/receipt-student/receipt-student.module').then((m) => m.ReceiptStudentPageModule),
  },
  {
    path: 'student/upoload-photo',
    loadChildren: () => import('./pages/student/upoload-photo/upoload-photo.module').then( m => m.UpoloadPhotoPageModule)
  },

  {
    path: 'how-to-signup',
    loadChildren: () => import('./pages/home/how-to-signup/how-to-signup.module').then( m => m.HowToSignupPageModule)
  },
  {
    path: 'create-account-with-branchcode',
    loadChildren: () => import('./pages/home/create-accountwith-branchcode/create-accountwith-branchcode.module').then( m => m.CreateAccountwithBranchcodePageModule)
  },

  {
    path: 'create-account-with-branchcode/:branchCode',
    loadChildren: () => import('./pages/home/create-accountwith-branchcode/create-accountwith-branchcode.module').then( m => m.CreateAccountwithBranchcodePageModule)
  },
  {
    path: 'face-capture-modal',
    loadChildren: () => import('./pages/home/modals/face-capture-modal/face-capture-modal.module').then( m => m.FaceCaptureModalPageModule)
  },
  {
    path: 'create-account-ofw',
    loadChildren: () => import('./pages/home/create-account-ofw/create-account-ofw.module').then( m => m.CreateAccountOfwPageModule)
  },
  {
    path: 'create-account-ofw/:dsCode',
    loadChildren: () => import('./pages/home/create-account-ofw/create-account-ofw.module').then( m => m.CreateAccountOfwPageModule)
  },
  {
    path: 'create-account-lto/:dsCode/:subId',
    loadChildren: () => import('./pages/home/create-account-lto/create-account-lto.module').then( m => m.CreateAccountLtoPageModule)
  },
  {
    path: 'create-account-lto',
    loadChildren: () => import('./pages/home/create-account-lto/create-account-lto.module').then( m => m.CreateAccountLtoPageModule)
  },






];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
