// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  background-position: center bottom;
  --background: #f0f6f9 url('student-bg.svg')
    no-repeat center bottom / cover;
}

ion-item {
  --ion-background-color: #fff;
}

.item-has-focus {
  --highlight-background: #e2e2e2;
}

@media (max-width: 540px) {
  ion-content {
    background-position: center bottom;
    --background: #f0f6f9 url('student-bg.svg')
      no-repeat center bottom / cover;
  }
  ion-item {
    --ion-background-color: #fff;
  }
  h1 {
    font-size: 8vw;
    font-family: "Otomanopee One", sans-serif;
    color: #292822;
    text-align: center;
  }
}
@media (min-width: 720px) {
  h1 {
    font-size: 8vw;
    font-family: "Otomanopee One", sans-serif;
    color: #292822;
    text-align: center;
  }
}
@media (min-width: 960px) {
  h1 {
    font-size: 2vw;
    font-family: "Otomanopee One", sans-serif;
    color: #292822;
    text-align: center;
  }
}
ion-item ion-icon {
  color: #fcd116;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/home/forgot/forgot.page.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA;mCAAA;AAEF;;AACA;EACE,4BAAA;AAEF;;AACA;EACE,+BAAA;AAEF;;AACA;EACE;IACE,kCAAA;IACA;qCAAA;EAGF;EAAA;IACE,4BAAA;EAEF;EACA;IACE,cAAA;IACA,yCAAA;IACA,cAAA;IACA,kBAAA;EACF;AACF;AAEA;EACE;IACE,cAAA;IACA,yCAAA;IACA,cAAA;IACA,kBAAA;EAAF;AACF;AAGA;EACE;IACE,cAAA;IACA,yCAAA;IACA,cAAA;IACA,kBAAA;EADF;AACF;AAIA;EACE,cAAA;AAFF","sourcesContent":["ion-content {\r\n  background-position: center bottom;\r\n  --background: #f0f6f9 url(\"../../../../assets/img/layout/student-bg.svg\")\r\n    no-repeat center bottom / cover;\r\n}\r\nion-item {\r\n  --ion-background-color: #fff;\r\n}\r\n\r\n.item-has-focus {\r\n  --highlight-background: #e2e2e2;\r\n}\r\n\r\n@media (max-width: 540px) {\r\n  ion-content {\r\n    background-position: center bottom;\r\n    --background: #f0f6f9 url(\"../../../../assets/img/layout/student-bg.svg\")\r\n      no-repeat center bottom / cover;\r\n  }\r\n  ion-item {\r\n    --ion-background-color: #fff;\r\n  }\r\n\r\n  h1 {\r\n    font-size: 8vw;\r\n    font-family: \"Otomanopee One\", sans-serif;\r\n    color: #292822;\r\n    text-align: center;\r\n  }\r\n}\r\n\r\n@media (min-width: 720px) {\r\n  h1 {\r\n    font-size: 8vw;\r\n    font-family: \"Otomanopee One\", sans-serif;\r\n    color: #292822;\r\n    text-align: center;\r\n  }\r\n}\r\n\r\n@media (min-width: 960px) {\r\n  h1 {\r\n    font-size: 2vw;\r\n    font-family: \"Otomanopee One\", sans-serif;\r\n    color: #292822;\r\n    text-align: center;\r\n  }\r\n}\r\n\r\nion-item ion-icon {\r\n  color: #fcd116;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
